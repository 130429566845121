<template>
  <div class="flex items-center">
    <vs-avatar :src="absoluteUrl(params.data.image)" class="flex-shrink-0 mr-2" size="30px" />
    <span  class="text-inherit hover:text-primary">{{ params.value || params.user.username }}</span>
  </div>
</template>

<script>
    export default {
      name: 'CellRendererLink',
    }
</script>
