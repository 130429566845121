<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon v-if="params.data.id != activeUser.id" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
  export default {
    name: 'CellRendererActions',
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      }
    },
    methods: {
      editRecord() {
        if(this.params.data.id == this.activeUser.id){
          this.$router.push({path: '/account'})
        }else{
          this.$router.push({path:`/users/${this.params.data.id}/edit`})
        }
      },
      confirmDeleteRecord() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirm Delete`,
          text: `You are about to delete "${this.params.data.first_name}"`,
          accept: this.deleteRecord,
          acceptText: "Delete"
        })
      },
      deleteRecord() {
        if(this.$route.params.userId < 0 ) {return}
        this.$vs.loading({ color: "#444",type: "sound" })
        this.$store.dispatch("userList/deleteUser", this.params.data.id)
        .then(response => {
          this.$vs.loading.close()
          if(response.data.success){
          }
        })
      },
    }
  }
</script>
