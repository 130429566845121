<template>
  <div class="flex items-center">
    <span class="text-inherit hover:text-primary">{{ params.value }}</span>
  </div>
</template>

<script>
    export default {
      name: 'CellRendererLink',
    }
</script>
